// Podcast.js
import React, { useEffect ,useState} from 'react';
import './bootstrap.min.css';
import './bootstrap-theme.min.css';
import './custom.css';
import { pad } from './pad';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_BASE_URL;
const Podcast = () => {

  const [faqs, setFaqs] = useState([]);
  const [footerMenu, setfooterMenu] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [description, setDescription] = useState({});
  const [podcasts, setPodcasts] = useState([]);
  const [studiosData, setStudiosData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const [getQuote, setgetQuote] = useState([]);
  useEffect(() => {
      fetch(`${apiUrl}/api/all-faqs`)
          .then(response => response.json())
          .then(data => setFaqs(data))
          .catch(error => console.error('Error fetching FAQs:', error));

          fetch(`${apiUrl}/api/get-quote`)
          .then(response => response.json())
          .then(data => {
            setgetQuote(data[0]); // Accessing the header data from the response
            //console.log(data[0]); // Logging the specific property
          })
          .catch(error => console.error('Error fetching header data:', error));

          fetch(`${apiUrl}/api/footer-menu`)
          .then(response => response.json())
          .then(data => {
            setfooterMenu(data); // Accessing the header data from the response
            //console.log(data.data); // Logging the specific property
          })
          .catch(error => console.error('Error fetching header data:', error));


          fetch(`${apiUrl}/api/podcast-content`)
  .then(response => response.json())
  .then(data => {
    console.log(data); // Log the entire response
    setDescription(data.description); // Ensure this is a string
  })
  .catch(error => console.error('Error fetching podcast content:', error));

  }, []);

  console.log(faqs);

  
useEffect(() => {
    axios.get(`${apiUrl}/api/website-meta`)
      .then(response => {
        const data = response.data[0]; // Assuming your API always returns an array with a single object
        setMetaData(data);
        updateMetaTags(data);
      })
      .catch(error => {
        console.error('Error fetching meta data:', error);
      });
  }, []);

useEffect(() => {
    const fetchPodcasts = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/podcasts`);
        const data = await response.json();
        setPodcasts(data);
      } catch (error) {
        console.error('Error fetching podcasts:', error);
      }
    };

    fetchPodcasts();
  }, []);

  const updateMetaTags = (data) => {
    var staticText = "Disruptors Media | "; 
    document.title = staticText + " " + data.podcast_meta_title; 
    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', data.podcast_meta_description); // Set meta description
    }
  };




  
const firstPodcast = podcasts[0];



  useEffect(() => {
    const fetchStudios = async () => {
      try {
        const response = await fetch('https://admin.disruptorsmedia.com/api/studios');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setStudiosData(data.studios);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudios();
  }, []);

  // Rest of your component logic/rendering
  return (

    <>
  
  
  <section className="main-sec faq">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <h1 className="fade-in-up">Podcast</h1>
        </div>
      </div>
    </div>
  </section>


  <section className="previous-podcast">

    
        <section className="main-banner abt pod">

      <div className="container">
         
          <div className="video-background abt">

                       <div className="video-container">
                       {firstPodcast ? (
                  <video id="myVideo" poster={`${apiUrl}/${firstPodcast.video_poster}`}>
                      <source src={`${apiUrl}/${firstPodcast.video_url}`} type="video/mp4" />
                      Your browser does not support the video tag.
                  </video>
                   ) : (
                    <p>Loading...</p>
                  )}
                  <div class="controls">
                    <span id="playBtn"><img src="./images/play-ico.png" /></span>
                    <span id="pauseBtn"><img src="./images/pause-icon.png" /></span>
                    <span id="muteBtn"><img src="./images/mute-icon.png" /></span>
                </div>
              </div>

          </div>

      </div>
  </section>

    <div className="container grid-pd">
        <div className="row">
          {podcasts.map((podcast) => (
            <div className="col-sm-3" key={podcast.id}>
              <div className="sm-video" data-src={`${apiUrl}/${podcast.video_url}`}>
                <img
                  src={`${apiUrl}/${podcast.video_poster}`}
                  alt={`Podcast ${podcast.id}`}
                />
              </div>
              <h3>{podcast.video_title}</h3>
            </div>
          ))}
        </div>
        
    </div>

  </section>

  <section className="ft-quote nopt-0 pd-top">
    <div className="container">
      <div className="row">
        
        <div className="col-sm-12">
          <div className="bdr-container bdr-container-upper">
            <div className="sep-main fr"></div>
            <div className="sep-main sec"></div>
            <div className="sep-main th"></div>
            <div className="sep-main ft"></div>
            <div className="sep-main fv"></div>
            <div className="sep-main sx"></div>
            <div className="book-call-cta top-pod">
              <a href="javascript:;">
                Past Podcasts <img src="./images/arrow-cta.svg" alt="*" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="main-text-pd">
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <p>{typeof description === 'string' ? description : 'Description not available.'}</p>
        </div>
      </div>
    </div>
  </section>


  <div className="podcast-new">
      <div className="container">
        {studiosData.map(studio => (
          <div key={studio.id} className="studio">
            <div className="row">
              <div className="col-sm-12">
                <h3>{studio.studio_title}</h3>
                <h5>{studio.location}</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <figure className="main-image">

                  <img src={`${apiUrl}/${studio.feature_image}`} alt="Podcast" />
                </figure>
              </div>
            </div>
            <div className="row more-btn-pd">
              {studio.galleries.slice(0, 2).map((image, index) => (
                <div key={index} className="col-sm-6">
                  <figure className="sm-image">
                    <img src={`${apiUrl}/${image.image}`} alt="Podcast" />
                    <span className="more-pd">
                      <img src="./images/more-pd.svg" alt="Podcast" />
                    </span>
                  </figure>
                </div>
              ))}
            </div>
            <div className="row hide-pd">
              {studio.galleries.slice(2).map((image, index) => (
                <div key={index} className="col-sm-6">
                  <figure className="sm-image">
                    <img src={`${apiUrl}/${image.image}`} alt="Podcast" />
                  </figure>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="col-sm-7">
                <h4>About the Space</h4>
                <p>{studio.about_content}</p>
              </div>
              <div className="col-sm-5 text-center">
                <h4 className="mpbx-32 sm">{studio.pricing_text}</h4>
                <a href={studio.booking_action_link} target="_blank" rel="noopener noreferrer" className="btn">BOOK now</a>
                <a href={studio.message_us_link} target="_blank" rel="noopener noreferrer" className="btn">Message US</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>


  <section className="ft-quote">
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <h3>{getQuote.main_heading}</h3>
        </div>
        <div className="col-sm-6 ft-r">
          <p>
            {getQuote.right_side_content}
          </p>
        </div>
        <div className="col-sm-12">
          <div className="bdr-container bdr-container-lower">
            <div className="sep-main fr"></div>
            <div className="sep-main sec"></div>
            <div className="sep-main th"></div>
            <div className="sep-main ft"></div>
            <div className="sep-main fv"></div>
            <div className="sep-main sx"></div>
            <div className="book-call-cta">
              <a href={getQuote.anchor_link}>
                {getQuote.anchor_text} <img src="./images/arrow-cta.svg" alt="*" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="pre-footer">
    <div className="container">
      <div className="row">
        <ul className="links">
        {footerMenu.map((footerMenu) => (
                    <li>
                      <a className="shuffle" href={footerMenu.menu_name}>{footerMenu.menu_name}</a>
                    </li>
                ))}

                 
                <li>
                  <a className="shuffle" href="/privacy-policy">Privacy Policy</a>
                </li>
                <li>
                  <a className="shuffle" href="/terms-conditions">Terms And Conditions</a>
                </li>
        </ul>
      </div>
    </div>
  </div>
  
</>




  );
}

export default Podcast;
